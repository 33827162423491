import { render, staticRenderFns } from "./assignVuln.vue?vue&type=template&id=72c0e434"
import script from "./assignVuln.vue?vue&type=script&lang=js"
export * from "./assignVuln.vue?vue&type=script&lang=js"
import style0 from "./assignVuln.vue?vue&type=style&index=0&id=72c0e434&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports