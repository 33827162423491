<template >
  <div class="border-primary rounded my-1 p-1" v-if="this.users.length > 0">
    <h5 class="text-primary">Assigned Users</h5>
    <ul v-for="user in users" :key="user">
      <li>
        {{ user.full_name }}
        <span class="ml-1">
          <b-button
            @click="removeUser(user.id)"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
          >
            <feather-icon icon="TrashIcon" /> </b-button
        ></span>
      </li>
    </ul>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BListGroupItem, BCardText, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";

export default {
  components: {
    BCardCode,
    BCardText,
    BListGroupItem,
    BButton,
  },
  props: {
    vuln_id: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      users: [],
      listAsset: this.$store.state.app.user.permissions.includes(
        "asset.list_all_org_vulnerabilities"
      ),
    };
  },
  created: function () {
    if (this.vuln_id != null && this.listAsset) {
      this.load();
    }
  },
  watch: {
    vuln_id() {
      if (this.listAsset) {
        this.load();
      }
    },
  },
  methods: {
    load: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.vuln_id +
          "/assigned-users",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        console.log(res.data);
        self.users = res.data.results;
      });
    },
    removeUser(id) {
      let data = {
        vulns: [this.vuln_id],
        user_id: id,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/remove-assigned-vulns/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.load();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>