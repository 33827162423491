 <template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
      <div>
        <b-badge class="mr-1" v-if="vuln.is_verified" variant="success"
          >Verified</b-badge
        >
        <b-badge class="mr-1" v-else variant="danger">Unverified</b-badge>

        <b-badge v-if="vuln.status == 0" variant="danger">Open</b-badge>
        <b-badge v-if="vuln.status == 1" variant="success">Closed</b-badge>
        <b-badge v-if="vuln.status == 2" variant="warning"
          >False Positive</b-badge
        >
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="editIssue(vuln)">Edit</b-dropdown-item>
          <b-dropdown-item
            v-if="vuln.status == 0"
            @click="doBulkAction(vuln, 1)"
            >Mark as closed</b-dropdown-item
          >
          <b-dropdown-item
            v-if="vuln.status == 0"
            @click="doBulkAction(vuln, 3)"
            >Accept the risk</b-dropdown-item
          >
          <b-dropdown-item
            v-if="vuln.status != 0"
            @click="doBulkAction(vuln, 0)"
            >Re-open</b-dropdown-item
          >
          <b-dropdown-item
            v-if="vuln.status == 0"
            @click="doBulkAction(vuln, 2)"
            >Mark as False Positive</b-dropdown-item
          >
          <div>
            <b-dropdown-item
              v-if="vuln.asset"
              @click="OpenTaskModal(vuln.asset.asset_id)"
              >Mitigate</b-dropdown-item
            >
            <b-dropdown-item v-else @click="OpenTaskModal(vuln.asset_id)"
              >Mitigate</b-dropdown-item
            >
          </div>

          <b-dropdown-divider />
          <b-dropdown-item
            v-if="vuln.is_verified && this.$store.state.app.user.is_staff"
            @click="changeverificationstatus(vuln, 'false')"
            >Mark as un-verified</b-dropdown-item
          > 
          <b-dropdown-item
            v-else-if="!vuln.is_verified && this.$store.state.app.user.is_staff"
            @click="changeverificationstatus(vuln, 'true')"
            >Mark as verified</b-dropdown-item
          >
          <b-dropdown-item @click="request_for_retest(vuln)"
            >Request for retest</b-dropdown-item
          >
          <b-dropdown-item @click="deleteIssue(vuln)">Delete</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-card-code :title="vuln.name">
      <!-- {{ vuln }} -->
      <!-- <span v-for="item in vuln.labels" :key="item.id">
        <b-badge variant="info" class="mr-1"
          >{{ item }}
          <feather-icon icon="XCircleIcon" @click="detachLabel(item)"/> </b-badge>
      </span>

      <b-button
        variant="flat-info"
        title="Add New"
        class="btn-icon"
        @click="openLabelModal()"
      >
        <feather-icon icon="PlusIcon" />
      </b-button> -->
      <div class="d-flex justify-content-between">
        <Labels :vuln="vuln" />
        <router-link :to="'/vulnerability/detail/' + vuln.id" target="_blank">
          <b-button
            variant="primary"
            size="sm"
            type="button"
            class="btn btn-sm btn-primary float-right text-white"
            ><span class="text-white">More Details</span></b-button
          >
        </router-link>
      </div>
      <div class="w-100">
        <b-card align="center" class="bg-dark-blue h-100">
          <div class="row">
            <div class="col">
              <b-list-group class="list-group-circle text-left bg-transparent">
                <b-list-group-item class="bg-transparent" v-if="vuln.asset">
                  <b>Asset:</b>
                  <span>
                    <router-link :to="'/assets/scan/' + vuln.asset.asset_id">
                      {{ vuln.asset.asset_name }}</router-link
                    ></span
                  >
                </b-list-group-item>
                <b-list-group-item class="bg-transparent" v-else>
                  <b> Asset ID:</b>
                  <span>
                    <router-link :to="'/assets/scan/' + vuln.asset_id">
                      {{ vuln.asset_id }}</router-link
                    ></span
                  >
                </b-list-group-item>
                <b-list-group-item
                  class="bg-transparent d-flex align-item-center"
                >
                  <b>Severity:</b>
                  <span class="w-100 ml-1"
                    ><b-form-select
                      size="sm"
                      class="w-100"
                      @change="updateSeverity(vuln)"
                      v-model="vuln.severity"
                      :options="severity_options"
                    ></b-form-select
                  ></span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>CVSS Score:</b><span class=""> {{ vuln.cvss_score }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>CVE:</b> <span class="">{{ vuln.cve }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Owasp Categories:</b>
                  <span class="">
                    <li v-for="item in vuln.owasp_categories" :key="item.value">
                      {{ item.value }}
                    </li>
                  </span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Path:</b> <span class="">{{ vuln.path }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b class="mr-1">Remediation Priority:</b>
                  <!-- <span class="text-secondary">{{ vuln.parameter }}</span> -->
                  <b-badge
                    style="background-color: #991d28"
                    v-if="vuln.cve_meta_data == 1"
                    >Critical</b-badge
                  >
                  <b-badge variant="danger" v-if="vuln.cve_meta_data == 2"
                    >High</b-badge
                  >
                  <b-badge variant="warning" v-if="vuln.cve_meta_data == 3"
                    >Medium</b-badge
                  >
                  <b-badge variant="success" v-if="vuln.cve_meta_data == 4"
                    >Low</b-badge
                  >
                  <b-badge variant="info" v-if="vuln.cve_meta_data == 5"
                    >Very Low</b-badge
                  >
                  <b-badge
                    variant="light-danger"
                    v-if="vuln.cve_meta_data == null"
                    >NA</b-badge
                  >
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b class="mr-1">CISA KEV:</b>
                  <b-badge variant="success" v-if="vuln.cisa_kev == true"
                    >Yes</b-badge
                  >
                  <b-badge variant="danger" v-if="vuln.cisa_kev == false"
                    >No</b-badge
                  >
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="col">
              <b-list-group class="list-group-circle text-left bg-transparent">
                <!-- <b-list-group-item class="bg-dark-blue">
                CVSS Score:
                <span class="text-secondary">{{ vuln.cvss_score }}</span>
              </b-list-group-item> -->
                <!-- <b-list-group-item class="bg-transparent">
                  <b>Path:</b> <span class="">{{ vuln.path }}</span>
                </b-list-group-item> -->

                <b-list-group-item class="bg-transparent">
                  <b>Port:</b> <span class="">{{ vuln.port }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Protocol:</b> <span class="">{{ vuln.protocol }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Parameter:</b>
                  <span class=""> {{ vuln.parameter }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Reported At:</b>
                  <span class=""> {{ vuln.created_at | formatdate }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Last Updated At:</b>
                  <span class=""> {{ vuln.updated_at | formatdate }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b> Source:</b>
                  <span class=""> {{ vuln.source }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b class="mr-1">EPSS:</b>
                  <span>{{ vuln.epss }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </b-card>
      </div>
      <div>
        <h5 class="text-primary">Synopsis</h5>
        <!-- v-html="synopsis" -->
        <!-- <p>{{ synopsis }}</p> -->
        <p v-html="synopsis"></p>
      </div>
      <div>
        <h5 class="text-primary">Description</h5>
        <!-- v-html="description" -->
        <!-- <p>{{ description }}</p> -->
        <p v-html="description"></p>
      </div>

      <div>
        <h5 class="text-primary">References</h5>
        <!-- <p>{{ references }}</p> -->
        <p v-html="references"></p>
        <!-- <b-form-textarea
          v-model="references"
          class="border-primary"
          rows="6"
          max-rows="10"
          disabled
        >
        </b-form-textarea> -->
      </div>
      <!-- <div v-if="nessus_evidence">
        <h5 class="text-primary">Evidence</h5>
        <b-form-textarea
          v-model="nessus_evidence"
          class="border-primary"
          rows="6"
          max-rows="10"
          disabled
        >
        </b-form-textarea>
      </div> -->
      <div class="mt-1">
        <h5 class="text-primary">Recommendations</h5>
        <!-- <b-form-textarea
          v-model="recommendation"
          class="border-primary"
          rows="4"
          max-rows="10"
          @change="updateRemediation(vuln.id)"
        ></b-form-textarea> -->
        <!-- <quill-editor
          @blur="updateRemediation(vuln.id)"
          v-model="recommendation"
          :options="editorOption"
        >
          <div id="toolbar" slot="toolbar">
            <button class="ql-bold">Bold</button>
            <button class="ql-italic">Italic</button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-list" value="ordered"></button>
          </div>
        </quill-editor> -->
        <p v-html="recommendation" class="border-primary rounded p-1"></p>
      </div>
      <div v-if="requests.length > 0">
        <h5 class="text-primary">Burp Evidences</h5>
        <h6 class="text-primary">Requests</h6>
        <p v-for="reqt in requests" :key="reqt.request_type">
          {{ reqt.request_type }}

          <b-form-textarea
            v-model="reqt.request_data"
            class="border-primary"
            rows="6"
            max-rows="10"
            disabled
          >
          </b-form-textarea>
        </p>
        <h6 class="text-primary">Responses</h6>
        <p v-for="resp in responses" :key="resp.response_data">
          {{ resp.response_type }}
          <b-form-textarea
            v-model="resp.response_data"
            class="border-primary"
            rows="6"
            max-rows="10"
            disabled
          >
          </b-form-textarea>
        </p>
      </div>
      <div class="">
        <AssignedUsers :vuln_id="vuln.id" />
      </div>

      <div id="myDiv">
        <!-- {{activeTab}} -->
        <b-tabs v-model="activeTab">
          <b-tab title="Evidences">
            <!-- side bar evidence new -->

            <b-tabs>
              <b-tab title="Tool Output" active>
                <div v-if="!requests.length && !nessus_evidence">
                  <div
                    class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
                  >
                    <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
                    <h3 class="font-weight-bolder">No Data Found</h3>
                  </div>
                </div>
                <div v-else>
                  <div v-if="nessus_evidence">
                    <h5 class="text-primary">Nessus Evidence</h5>
                    <b-form-textarea
                      v-model="nessus_evidence"
                      class="border-primary"
                      rows="6"
                      max-rows="10"
                      disabled
                    >
                    </b-form-textarea>
                  </div>
                  <div v-if="requests.length > 0" class="mt-1">
                    <h5 class="text-primary">Burp Evidence</h5>
                    <h6 class="text-primary">Requests</h6>
                    <p v-for="reqt in requests" :key="reqt.request_type">
                      {{ reqt.request_type }}

                      <b-form-textarea
                        v-model="reqt.request_data"
                        class="border-primary"
                        rows="6"
                        max-rows="10"
                        disabled
                      >
                      </b-form-textarea>
                    </p>
                    <h6 class="text-primary">Responses</h6>
                    <p v-for="resp in responses" :key="resp.response_data">
                      {{ resp.response_type }}
                      <b-form-textarea
                        v-model="resp.response_data"
                        class="border-primary"
                        rows="6"
                        max-rows="10"
                        disabled
                      >
                      </b-form-textarea>
                    </p>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Steps to Reproduce Vulnerability" lazy>
                <div v-if="steps_to_reproduce.length">
                  <b-list-group-item
                    v-for="step in steps_to_reproduce"
                    :key="step.filename"
                    tag="li"
                    class="my-1 border-0 bg-transparent"
                  >
                    <!-- <StepToReproduceCard :step="step" :steps_files="steps_files" :editSteps="null" :gotoDelete="null" /> -->
                    <StepBigImageViewCard
                      :step="step"
                      :steps_files="steps_files"
                    />
                    <!-- <div class="row">
                    <div class="col-12 ml-25">
                      <b-form-textarea style="font-size: 17px"
                        class="mt-1 border-0 text-primary bg-transparent font-weight-bold" v-model="step.title"
                        disabled></b-form-textarea>

                      <div class="mt-1" v-if="!step.filename.includes('__no_file_')">
                        <img style="max-width: 100%" height="auto" :ref="step.filename" :src="steps_files[step.filename]"
                          :alt="step.caption" />
                        <div class="mt-1">
                          <h4 class="text-primary text-center">
                            {{ step.caption }}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  </b-list-group-item>
                </div>
                <div v-else>
                  <div
                    class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
                  >
                    <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
                    <h3 class="font-weight-bolder">No Data Found</h3>
                  </div>
                </div>
              </b-tab>

              <b-tab title="Retest Evidences" lazy>
                <div v-if="retest_evidence.length">
                  <b-list-group-item
                    v-for="(listItem, index) in retest_evidence"
                    :key="listItem.filename"
                    tag="li"
                    class="my-1 border-0 bg-transparent"
                  >
                    <!-- <div class="row">
                    <div class="col-12 ml-25">
                      <b-form-textarea style="font-size: 17px"
                        class="mt-1 border-0 text-primary bg-transparent font-weight-bold" v-model="listItem.description"
                        disabled></b-form-textarea>
                      <div class="mt-1" v-if="!listItem.filename.includes('__no_file_')">
                        <img style="max-width: 100%" height="auto" :ref="listItem.filename"
                          :src="retest_files[listItem.filename]" :alt="listItem.caption" />
                        <div class="mt-1">
                          <h4 class="text-primary text-center">
                            {{ listItem.caption }}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div> -->
                    <StepBigImageViewCard
                      :step="listItem"
                      :steps_files="retest_files"
                      :index="index + 1"
                    />
                  </b-list-group-item>
                </div>
                <div v-else>
                  <div
                    class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
                  >
                    <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
                    <h3 class="font-weight-bolder">No Data Found</h3>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Additional Evidences" lazy>
                <div v-if="attached_files">
                  <b-list-group-item
                    v-for="listItem in attached_files"
                    :key="listItem.filename"
                    tag="li"
                    class="my-1"
                  >
                    <div class="row">
                      <div class="col-12 ml-25">
                        <!-- <b-form-textarea
                        style="font-size: 17px"
                        class="border-0 text-primary bg-transparent font-weight-bold"
                        v-model="listItem.description"
                        disabled
                      ></b-form-textarea> -->
                        <p class="" v-html="listItem.description"></p>
                      </div>
                      <div class="col-12 d-flex justify-content-between">
                        <div>
                          <b-card-text class="font-weight-bold mb-0 ml-1">
                            {{ listItem.filename }}
                          </b-card-text>
                        </div>
                        <small class="d-flex justify-content-end">
                          <b-button
                            size="sm mr-1"
                            variant="info"
                            @click="viewAditionalAttachment(listItem.filename)"
                          >
                            <span
                              v-if="
                                viewloader && listItem.filename == viewAditional
                              "
                              >Please wait</span
                            >
                            <span v-else>View</span>
                          </b-button>
                          <b-button
                            size="sm"
                            variant="primary"
                            @click="
                              downloadAditionalAttachment(listItem.filename)
                            "
                          >
                            <span
                              v-if="
                                isdownloader &&
                                listItem.filename == fileAditional
                              "
                              >Please wait</span
                            >
                            <span v-else>Download</span>
                          </b-button>
                        </small>
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-card-code
                    class="mt-1 card shadow-none bg-transparent border-primary"
                    title="Preview"
                    v-if="image_data || video_data || isviewloading"
                  >
                    <div
                      class="d-flex justify-content-center mb-1"
                      v-if="isviewloading"
                    >
                      <b-spinner class="float-right" label="Floated Right" />
                    </div>
                    <img
                      v-bind:src="image_data"
                      v-if="image_data"
                      style="width: 100%"
                    />
                    <video
                      width="100%"
                      height="auto"
                      v-if="video_data"
                      controls
                    >
                      <source v-bind:src="video_data" type="video/mp4" />
                    </video>
                  </b-card-code>
                </div>
                <div v-else>
                  <div
                    class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
                  >
                    <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
                    <h3 class="font-weight-bolder">No Data Found</h3>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <!-- 
          <div class="mt-1">
            <div v-if="nessus_evidence">
              <h5 class="text-primary">Nessus Evidence</h5>
              <b-form-textarea
                v-model="nessus_evidence"
                class="border-primary"
                rows="6"
                max-rows="10"
                disabled
              >
              </b-form-textarea>
            </div>
            <div v-if="requests.length > 0" class="mt-1">
              <h5 class="text-primary">Burp Evidence</h5>
              <h6 class="text-primary">Requests</h6>
              <p v-for="reqt in requests" :key="reqt.request_type">
                {{ reqt.request_type }}

                <b-form-textarea
                  v-model="reqt.request_data"
                  class="border-primary"
                  rows="6"
                  max-rows="10"
                  disabled
                >
                </b-form-textarea>
              </p>
              <h6 class="text-primary">Responses</h6>
              <p v-for="resp in responses" :key="resp.response_data">
                {{ resp.response_type }}
                <b-form-textarea
                  v-model="resp.response_data"
                  class="border-primary"
                  rows="6"
                  max-rows="10"
                  disabled
                >
                </b-form-textarea>
              </p>
            </div>
          </div> -->
          </b-tab>
          <b-tab title="Logs">
            <LogView :vuln_id="vuln.id" :activeTab="activeTab" />
          </b-tab>
          <b-tab title="Tasks">
            <TaskSidebar :vuln_id="vuln.id" :activeTab="activeTab" />
          </b-tab>
          <b-tab title="Discussions">
            <VulnComments
              :vuln_id="vuln.id"
              :organization="organization"
              :activeTab="activeTab"
            />
          </b-tab>
          <b-tab
            title="Associated Vulnerabilities"
            v-if="vuln && vuln.child_vulns && vuln.child_vulns.length > 0"
          >
            <div
              v-if="vuln && vuln.child_vulns && vuln.child_vulns.length > 0"
              class="border-primary rounded p-1 mt-1"
            >
              <!-- <h5 class="text-primary">Associate Vulnerability</h5> -->
              <div class="custom-list">
                <ul
                  v-for="vulnerability in vuln.child_vulns"
                  :key="vulnerability.id"
                >
                  <li>
                    <a
                      @click="redirectToDetail(vulnerability.id)"
                      target="_blank"
                      >{{ vulnerability.name }}</a
                    >
                    <span class="ml-1">
                      <b-badge
                        variant="light-danger"
                        v-if="vulnerability.status == 0"
                        >Open</b-badge
                      >
                      <b-badge
                        variant="light-success"
                        v-if="vulnerability.status == 1"
                        >Closed</b-badge
                      >
                      <b-badge
                        variant="light-warning"
                        v-if="vulnerability.status == 2"
                        >False Positive</b-badge
                      >
                      <b-badge
                        variant="light-success"
                        v-if="vulnerability.status == 3"
                        >Accepted</b-badge
                      >
                      <b-badge
                        variant="light-warning"
                        v-if="vulnerability.status == 4"
                        >Assigned</b-badge
                      >
                      <b-badge
                        variant="light-warning"
                        v-if="vulnerability.status == 5"
                        >Mitigated</b-badge
                      >
                      <b-badge
                        variant="light-danger"
                        v-if="vulnerability.status == 9"
                        >Deleted</b-badge
                      >
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
          <b-tab title="Parent Vulnerability" v-if="vuln.parent_vuln != null">
            <div
              v-if="vuln.parent_vuln != null"
              class="border-primary rounded p-1 mt-1"
            >
              <!-- <h5 class="text-primary">Parent Vulnerability</h5> -->
              <p>
                <a @click="redirectToDetail(vuln.parent_id)" target="_blank">{{
                  vuln.parent_vuln.name
                }}</a>
              </p>
            </div>
          </b-tab>
          <b-tab title="CVE MetaData">
            <CVEMetaData :cveMetaData="cveMetaData" />
          </b-tab>
        </b-tabs>
      </div>
      <b-modal
        ref="modal-mitigate-vuln"
        title="Add Task"
        hide-footer
        size="lg"
        no-close-on-backdrop
      >
        <p class="my-1">Add a task to mitigate this issue!</p>
        <TaskAddForm
          :asset_id="vuln_asset_id"
          :organization="organization"
          :callBack="afterTaskAdd"
          :task="vuln.name"
          :description="recommendation"
          task_type="WEB_APP_VULNERABILITY"
          :vuln_id="vuln.id"
          :vuln="vuln"
          :assetOwner="assetOwner"
        />
      </b-modal>
    </b-card-code>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BModal,
  BPagination,
  BPaginationNav,
  BBadge,
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BListGroup,
  BListGroupItem,
  BTab,
  BTabs,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TaskAddForm from "./TaskAddForm.vue";
import AssignedUsers from "./../../Vulnerability/components/assignedUsers.vue";
import CVEMetaData from "./../../Vulnerability/components/cveMetaSidebar.vue";
import Labels from "./../../Vulnerability/components/Labels.vue";
import LogView from "./../../Vulnerability/components/LogView.vue";
import VulnComments from "./../../Vulnerability/components/Comments.vue";
// import Tasks from "./../../Task/components/tasks.vue";
import TaskSidebar from "./../../Task/components/taskSidebar.vue";
import moment from "moment";
import StepToReproduceCard from "@/views/Vulnerability/components/StepToReproduceCard.vue";
import StepBigImageViewCard from "@/views/Vulnerability/components/StepBigImageViewCard.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BSidebar,
    VBToggle,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
    TaskAddForm,
    BRow,
    vSelect,
    Labels,
    BListGroup,
    BListGroupItem,
    VulnComments,
    // Tasks,
    TaskSidebar,
    LogView,
    BCardText,
    BSpinner,
    StepToReproduceCard,
    StepBigImageViewCard,
    CVEMetaData,
    AssignedUsers,
    quillEditor,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  // model: {
  //   prop: 'isTaskHandlerSidebarActive',
  //   event: 'update:is-task-handler-sidebar-active',
  // },
  props: {
    vuln: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    asset_id: {
      type: Number,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    assetOwner: {
      type: Number,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      vuln: null,
      tasks: [],
      vuln_id: this.$route.params.id,
      asset_id: "",
      vuln_asset_id: "",
      description: "-------",
      synopsis: "-------",
      path: "",
      origin: "",
      recommendation: "-------",
      nessus_evidence: "-------",
      burp_evidence: [],
      requests: [],
      responses: [],
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
      references: "-------",
      attached_files: [],
      loading: false,
      disabled: true,

      images: {},
      loader: "",
      viewloader: false,

      imageSrc: null,

      steps_files: [],
      retest_files: [],
      isdownloader: false,
      isviewloading: false,
      retest_evidence: [],
      steps_to_reproduce: [],
      image_data: false,
      video_data: false,
      cveMetaData: [],
      // activeTab:this.activeTab,
    };
  },
  // created: function () {
  //     this.vuln = 1
  //   },
  watch: {
    vuln(value) {
      // this.viewAttachment();
      // this.downloadAttachment();
      this.burp_evidence = [];
      this.requests = [];
      this.responses = [];
      this.description = "-------";
      this.synopsis = "-------";
      this.recommendation = "-------";
      this.references = "-------";
      this.nessus_evidence = "-------";
      this.attached_files = [];
      this.retest_files = [];
      this.steps_files = [];
      this.steps_to_reproduce = [];
      this.retest_evidence = [];
      // meta data
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          value.id +
          "/cve-meta-data/",
      };
      this.$http(options).then((res) => {
        this.cveMetaData = res.data;
      });
      // this.cveMetaData();
      // this.asset_id = "";
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/vulns/" + value.id, //+'/vuln-details/',
      };
      var self = this;
      this.$http(o_options).then((res) => {
        console.log("New respoense", res.data);
        self.description = res.data.description;
        self.synopsis = res.data.synopsis;
        self.recommendation = res.data.recommendation;
        self.nessus_evidence = res.data.nessus_evidence;
        self.references = res.data.references;
        if (self.vuln.additional_evidence) {
          var f_data = JSON.parse(self.vuln.additional_evidence);
          console.log("Additonal Evidence", f_data);
          // f_data.map(function(filename, filedetails) {
          self.attached_files = f_data;
          Object.keys(f_data).map(function (filename, filedetails) {
            console.log("filenam", filename);
          });
        }
        self.burp_evidence = res.data.burp_evidence;
        // self.asset_id = res.data.asset_id;
        if (res.data.burp_evidence) {
          res.data.burp_evidence.map(function (k, v) {
            k["request_response"]["request"].map(function (r, v) {
              let req = {
                request_type: r["type"],
                request_data: atob(r["data"]),
              };
              self.requests.push(req);
            });
            k["request_response"]["response"].map(function (a, b) {
              let res = {
                response_type: a["type"],
                // response_data:self.fromBinary(a['data'])
                response_data: atob(a["data"]),
              };
              self.responses.push(res);
            });
          });
        }
      });
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          value.id +
          "/vuln-details/",
      }).then((res) => {
        // var data = JSON.parse(res.data);
          var data = res.data;
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        // data.map(function (key, value) {
          console.log("V-Data", data);
          let keyy = data;
          // if (key["details"]["additional_evidence"]) {
          //   var f_data = JSON.parse(key["details"]["additional_evidence"]);
          //   console.log(f_data);
          //   // f_data.map(function(filename, filedetails) {
          //   self.attached_files = f_data;
          //   Object.keys(f_data).map(function (filename, filedetails) {
          //     console.log("filenam", filename);
          //   });
          // }
          if (keyy["steps_to_reproduce"]) {
            var f_data = JSON.parse(keyy["steps_to_reproduce"]);
            console.log(f_data);
            // f_data.map(function(filename, filedetails) {
            // self.steps_to_reproduce = f_data;
            const new_steps_to_reproduce = [];
            Object.values(f_data).map((data) => {
              new_steps_to_reproduce[data.order_id - 1] = data;
            });
            self.steps_to_reproduce = new_steps_to_reproduce;
            Object.keys(f_data).map(function (filename, filedetails) {
              console.log("filenam", filename);
              self.downloadAttachment(filename, "steps_to_reproduce");
            });
          }
          if (keyy["retest_evidence"]) {
            var f_data = JSON.parse(keyy["retest_evidence"]);
            console.log(f_data);
            // f_data.map(function(filename, filedetails) {
            self.retest_evidence = Object.values(f_data);
            console.log("Retest Evidence", self.retest_evidence);
            Object.keys(f_data).map(function (filename) {
              console.log("filenam", filename);
              self.downloadAttachment(filename, "retest_evidence");
            });
          }
        // });
      });
    },
  },
  // mounted() {
  // this.viewAttachment();
  // this.downloadAttachment(filename, key);
  // this.downloadAditionalAttachment();
  // },
  mounted() {
    this.$emit("div-id", "myDiv");
  },
  created: function () {
    // this.load();
  },
  methods: {
    redirectToDetail(id) {
      this.$router.push(`/vulnerability/detail/${id}`);
    },
    // handleBlur() {
    //   this.updateRemediation(vuln.id);
    // },
    // moreDetailClick( route) {
    //   this.$cookies.set(
    //     "redirectPath",
    //     route,
    //     null,
    //     null,
    //     process.env.VUE_APP_DOMAIN
    //   );
    //   const url = `${window.location.origin}/#/app-switch/`;
    //   window.open(url, "_blank");
    // },
    viewAttachment(filename, value) {
      const options = {
        method: "GET",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          vuln.id +
          "/vuln-details/",
        responseType: "bolb",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(
          res.data,
          "res imagehhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh"
        );
        this.image_data = window.URL.createObjectURL(res.data);
        const base64 = new TextEncoder().encode(response.data);
        this.imageSrc = `data:image/jpeg;base64,${base64}`;
      });
    },

    downloadAttachment(filename, key) {
      this.loading = true;
      this.loader = true;
      var url;
      if (!filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.vuln.id +
          "/download-attachment/";

      if (key == "steps_to_reproduce" && !filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.vuln.id +
          "/download-steps-to-reproduce-evidences/";

      if (key == "retest_evidence" && !filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.vuln.id +
          "/download-retest_evidence/";

      const options = {
        method: "POST",
        data: { filename: filename },
        url: url,
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        // this.images.filename = url;
        // this.$refs[filename].src = url
        if (key == "steps_to_reproduce")
          this.$set(self.steps_files, filename, url);
        if (key == "retest_evidence")
          this.$set(self.retest_files, filename, url);
        // self.images.push({[filename]:url})
        // this.loading = false;
        // const url = window.URL.createObjectURL(new Blob([res.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", filename); //or any other extension
        // document.body.appendChild(link);
        // link.click();
        // this.loader = false;
      });
    },

    downloadAditionalAttachment(filename) {
      this.isviewloading = true;
      this.isdownloader = true;
      this.fileAditional = filename;
      const options = {
        method: "POST",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.vuln.id +
          "/download-attachment/",
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.isviewloading = false;
        this.isdownloader = false;
      });
    },

    viewAditionalAttachment(filename) {
      this.isviewloading = true;
      this.viewloader = true;
      this.viewAditional = filename;
      this.video_data = this.image_data = false;
      const options = {
        method: "POST",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.vuln.id +
          "/download-attachment/",
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        //this.image_data = new Blob([res.data])
        var ext = filename.split(".").pop();
        console.log(ext);
        if (ext == "mp4")
          this.video_data = window.URL.createObjectURL(new Blob([res.data]));
        else if (ext == "jpeg" || ext == "jpg" || ext == "png")
          this.image_data = window.URL.createObjectURL(new Blob([res.data]));
        else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        this.isviewloading = false;
        this.viewloader = false;
      });
    },

    fromBinary: function (encoded) {
      return decodeURIComponent(
        atob(encoded)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
    },
    updateRemediation: function (id) {
      let data = {
        recommendation: this.recommendation,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          id +
          "/update-details/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully updated",
            icon: "editIcon",
            variant: "success",
          },
        });
      });
    },
    // currently not using this api because of mongo not supporting patch method instead of this using doBulkAction()
    updateIssue: function (vuln, status) {
      //if(confirm("Do you really want to mark this as false positive?")){
      let data = {
        status: status,
        severity: this.severity,
        // remedy: "Test"
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/vulns/" + vuln.id + "/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.vuln.status = status;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully updated",
            icon: "editIcon",
            variant: "success",
          },
        });
      });
      // }
    },
    doBulkAction: function (vuln, status) {
      var self = this;
      this.loading = true;
      let data = {
        status: status,
        vulnerabilties: [this.vuln.id],
      };

      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/update-vulnerability-in-bulk/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.closeSidebar();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
    },
    updateSeverity: function (vuln) {
      //if(confirm("Do you really want to mark this as false positive?")){
      let data = {
        severity: vuln.severity,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/vulns/" + vuln.id + "/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully updated",
            icon: "editIcon",
            variant: "success",
          },
        });
      });
      // }
    },
    changeverificationstatus: function (vuln, value) {
      // let verified_by = "";
      // if (value) verified_by = this.$store.state.app.user.email;

      let data = {
        is_verified: value,
        // verified_by: verified_by,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          vuln.id +
          "/mark-as-verified/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully changed",
            icon: "editIcon",
            variant: "success",
          },
        });
        this.closeSidebar();
      });
    },
    request_for_retest: function (vuln) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          vuln.id +
          "/request-for-retest/",
      };
      this.$http(options).then((res) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully requested for retest",
            icon: "editIcon",
            variant: "success",
          },
        });
        this.closeSidebar();
      });
    },
    deleteIssue: function (vuln) {
      let data = {
        status: status,
      };
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/vulns/" + vuln.id + "/",
      };
      this.$http(options).then((res) => {
        // this.callBack()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully deleted",
            icon: "trashIcon",
            variant: "success",
          },
        });
        this.closeSidebar();
      });
    },
    editIssue: function (vuln) {
      this.$router.push({
        name: "Edit Vulnerability",
        params: { id: vuln.id },
      });
    },
    afterTaskAdd: function () {
      this.$refs["modal-mitigate-vuln"].hide();
    },
    OpenTaskModal: function (id) {
      this.vuln_asset_id = id;
      // this.task_title = "Please close port "+port+" of asset "+ip
      this.$refs["modal-mitigate-vuln"].show();
    },
    cveMetaData: function () {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          value.id +
          "/cve-meta-data/",
      };
      this.$http(options).then((res) => {});
    },
  },
};
</script>
<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}

.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #131721 !important;
  color: #fff;
}

[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
// [dir].dark-layout .list-group-item {
//   padding: 0.75rem 1.25rem;
//   background-color: #283046 !important;
//   border: 1px solid rgb(68 101 129) !important;
// }
</style>
<style scoped>
.custom-list {
  min-height: 150px;
  max-height: 100px;
  overflow-y: auto;
}
.custom-list li:hover {
  color: #7367f0; /* Change the text color to blue on hover */
}
[dir] .list-group-item {
  background-color: #fff0 !important;
}
</style>