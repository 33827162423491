<template>
  <b-card-code>
    <b-table
      responsive
      id="cvdTable"
      :fields="fields"
      :items="cveMetaData"
      :per-page="perPage"
      v-if="cveMetaData.length"
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(cvss_severity)="data">
        <b-badge
          style="background-color: #991d28"
          v-if="data.item.cvss_severity == 'CRITICAL'"
          >Critical</b-badge
        >
        <b-badge variant="danger" v-if="data.item.cvss_severity == 'HIGH'"
          >High</b-badge
        >
        <b-badge variant="warning" v-if="data.item.cvss_severity == 'MEDIUM'"
          >Medium</b-badge
        >
        <b-badge variant="success" v-if="data.item.cvss_severity == 'LOW'"
          >Low</b-badge
        >
        <b-badge variant="info" v-if="data.item.cvss_severity == 'INFO'"
          >Info</b-badge
        >
      </template>
      <template #cell(cisa_kev)="data">
        <b-badge variant="light-success" v-if="data.item.cisa_kev == true">
          Yes
        </b-badge>
        <b-badge variant="light-danger" v-else> No </b-badge>
      </template>
      <template #cell(priority)="data">
        <b-badge
          style="background-color: #991d28"
          v-if="data.item.priority == 'Priority 1+'"
        >
          1+
        </b-badge>
        <b-badge variant="danger" v-if="data.item.priority == 'Priority 1'">
          1
        </b-badge>
        <b-badge variant="warning" v-if="data.item.priority == 'Priority 2'">
          2
        </b-badge>
        <b-badge variant="success" v-if="data.item.priority == 'Priority 3'">
          3
        </b-badge>
        <b-badge variant="info" v-if="data.item.priority == 'Priority 4'">
          4
        </b-badge>
      </template>
    </b-table>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
  },
  data() {
    return {
      fields: [
        { key: "#", label: "#" },
        { key: "cve_id", label: "CVE" },
        { key: "priority", label: "Priority" },
        // { key: "epss", label: "EPSS" },
        { key: "cvss", label: "CVSS" },
        // { key: "cvss_version", label: "CVSS Version" },
        { key: "cvss_severity", label: "CVSS Severity" },
        { key: "cisa_kev", label: "CISA KEV" },
      ],
      cveMetaData: [],
    };
  },
  props: {
    cveMetaData: {
      type: Array,
      required: true,
    },
  },

  created: function () {},
  methods: {},
};
</script>
