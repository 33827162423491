<template>
  <div>
    <b-card-code>
      <div class="mb-1 d-flex justify-content-between">
        <h2>{{ name }}</h2>
        <router-link :to="'/assets/pt-vulnerabilities'">
          <b-button
            type="button"
            variant="primary"
            class="ml-auto float-right"
            size="sm"
          >
            Back
          </b-button>
        </router-link>
      </div>
      <!-- Search Form -->
      <b-card no-body style="width: 100%">
        <b-card-code class="bg-light-secondary">
          <div class="d-flex justify-content-between mb-1">
            <div class="d-flex align-items-center">
              <h4 class="mb-0">Search</h4>
              <b-form-checkbox
                v-model="isHidden"
                class="custom-control-primary ml-1"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="ChevronsUpIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="ChevronsDownIcon" />
                </span>
              </b-form-checkbox>
              <span v-if="!isHidden"> Show More Search</span>
              <span v-else> Show Less</span>

              <!-- </div> -->
            </div>

            <div class="mr-1">
              <!-- <router-link :to="'/assets/upload-nessus-report'">
                <b-button
                  type="button"
                  variant="outline-primary"
                  class="ml-auto float-right mr-1"
                  size="sm"
                >
                  Upload Vulnerability
                </b-button>
              </router-link>
              <router-link :to="'/vulnerability/add-vulnerability'">
                <b-button
                  type="button"
                  variant="outline-primary"
                  class="mr-1 ml-auto float-right"
                  size="sm"
                >
                  Add Vulnerability
                </b-button>
              </router-link> -->
            </div>
          </div>

          <b-form inline style="display: flex; width: 100%">
            <div
              class="row"
              style="
                padding: 10px;
                width: 100%;
                display: inline-flex;
                flex-wrap: nowrap;
              "
            >
              <!-- <b-form-input
                class="ml-1"
                style="width: 53%"
                id="name"
                v-model="name_filter"
                placeholder="Vulnerability Name"
              /> -->

              <v-select
                v-model="group_filter"
                multiple
                label="text"
                class="ml-1"
                @search="fetchGroup"
                placeholder="--Search Group--"
                :options="group_filter_options"
                autocomplete
                style="width: 100%"
                :reduce="(group_filter) => group_filter.value"
              />
              <!-- {{ group_filter }} -->
              <!-- {{ group_filter_options }} -->
              <!-- @search="searchFn" -->

              <v-select
                v-model="severity_filter"
                multiple
                label="text"
                taggable
                class="ml-1"
                placeholder="--Select Severity--"
                :options="severity_filter_options"
                autocomplete
                style="width: 100%"
                :reduce="(severity_filter) => severity_filter.value"
              />
              <!-- @search="searchFn" -->
            </div>
            <!-- {{ severity_filter }} -->

            <div
              class="row"
              style="
                padding: 10px;
                width: 100%;
                display: inline-flex;
                flex-wrap: nowrap;
              "
            >
              <v-select
                v-model="status_filter"
                multiple
                label="text"
                taggable
                class="ml-1"
                placeholder="--Select Status--"
                :options="status_filter_options"
                autocomplete
                style="width: 100%"
                :reduce="(status_filter) => status_filter.value"
              />
              <!-- {{ status_filter }} -->
              <!-- @search="searchFn" -->
              <!-- <v-select
              v-model="multi_asset_filter"
              multiple
              label="text"
              class="ml-1"
              placeholder="--Search Asset--"
              :options="multi_asset_options"
              @search="fetchOptions"
              autocomplete
              style="width: 100%"
            /> -->
              <b-form-input
                class="ml-1 w-100"
                id="assetname"
                v-model="assetname_filter"
                name="name"
                placeholder="Search with Asset Name"
              />
              <!-- :reduce="(multi_asset_filter) => multi_asset_filter.value" -->
              <b-form-select
                class="ml-1"
                v-model="port_type"
                :options="port_type_options"
                style="width: 25%"
              >
              </b-form-select>
              <b-form-input
                class="ml-1"
                style="width: 20%"
                id="port"
                v-model="port_filter"
                placeholder="Port"
              />
            </div>
            <!-- more filters -->
            <div style="width: 100%" v-if="isHidden">
              <div
                class="row"
                style="
                  padding: 10px;
                  width: 100%;
                  display: inline-flex;
                  flex-wrap: nowrap;
                "
              >
                <b-form-select
                  class="ml-1"
                  style="width: 100%"
                  v-model="is_verified"
                  :options="verified_filter_options"
                >
                </b-form-select>
                <b-form-select
                  class="ml-1"
                  style="width: 100%"
                  v-model="is_exploited"
                  :options="exploited_filter_options"
                >
                </b-form-select>
                <b-form-select
                  class="ml-1"
                  style="width: 100%"
                  v-model="is_assigned"
                  :options="assigned_filter_options"
                >
                </b-form-select>
              </div>

              <div
                class="row"
                style="
                  padding: 10px;
                  padding-left: 20px;
                  width: 100%;
                  display: inline-flex;
                  flex-wrap: nowrap;
                "
              >
                <v-select
                  v-model="label_filter"
                  taggable
                  multiple
                  label="text"
                  style="width: 45%"
                  :options="label_options"
                  @search="fetchLabels"
                  autocomplete
                  placeholder="Search with labels"
                  :reduce="(label_filter) => label_filter.value"
                />
                <v-select
                  v-model="remediation_filter"
                  multiple
                  label="text"
                  taggable
                  class="ml-1"
                  placeholder="--Select Remediation Priority--"
                  :options="remediation_filter_options"
                  autocomplete
                  style="width: 55%"
                  :reduce="(remediation_filter) => remediation_filter.value"
                />
                <div class="d-flex align-items-center">
                  <flat-pickr
                    placeholder="Start Date"
                    style="width: 100%; color: #7367f0"
                    v-model="fromDate"
                    class="form-control ml-1 mr-1 bg-background"
                  />
                  <span>to</span>

                  <flat-pickr
                    placeholder="End Date"
                    style="width: 100%; color: #7367f0"
                    v-model="endDate"
                    class="form-control ml-1 bg-background"
                    :disabled="fromDate ? false : true"
                  />
                </div>
                <!-- <b-button
                style="width: 20%"
                class="ml-1"
                variant="primary"
                @click="searchFn"
              >
                <span v-if="isloading"
                  ><b-spinner type="border" small></b-spinner> Please wait</span
                >
                <span v-else>
                  <feather-icon icon="SearchIcon" class="mr-50" />Search</span
                >
              </b-button> -->
              </div>
            </div>
            <div class="w-100 d-flex justify-content-end align-items-center">
              <b-button
                style="width: 15%"
                class="mr-2"
                variant="secondary"
                @click="refreshFn"
                size="sm"
              >
                <span>
                  <feather-icon icon="RefreshCwIcon" class="mr-50" />Reset</span
                >
              </b-button>
              <b-button
                style="width: 15%"
                class="mr-2"
                variant="primary"
                @click="searchFn"
                size="sm"
              >
                <span v-if="isloading"
                  ><b-spinner type="border" small></b-spinner> Please wait</span
                >
                <span v-else>
                  <feather-icon icon="SearchIcon" class="mr-50" />Search</span
                >
              </b-button>
            </div>
          </b-form>
        </b-card-code>

        <b-form inline style="p0 margin-top: 30px; margin-bottom: -30px">
          <b-form-checkbox
            class="ml-1"
            name="selected-items"
            v-model="isCheckAll"
            label="Select All"
            @change="checkAll()"
          >
            Select all in this page
          </b-form-checkbox>
          <b-form-checkbox
            class="ml-1"
            name="selected-items"
            v-model="isCheckAllFilterResults"
            label="Select All"
            v-if="is_search_on"
          >
            Select all issues that matches this search
          </b-form-checkbox>
          <!-- <p>jj{{isCheckAllFilterResults}}</p> -->

          <div class="clearfix ml-auto">
            <b-dropdown
              variant="outline-secondary"
              text="More Columns"
              checkbox-menu
              allow-focus
              class="mr-1"
            >
              <b-dropdown-form>
                <b-form-checkbox
                  style="margin-top: 2px; margin-bottom: 2px"
                  :disabled="visibleFields.length == 1 && field.visible"
                  v-for="field in fields.slice(2)"
                  :key="field.key"
                  v-model="field.visible"
                  inline
                >
                  {{ field.label }}
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>
            <b-form-select
              class="mr-1"
              v-model="selected_action"
              :options="filteredOptions"
              @change="doBulkAction"
            >
            </b-form-select>
            <b-form-select
              v-model="selected_action"
              :options="with_selected_options"
              @change="doBulkAction"
            >
            </b-form-select>
            <b-form-select
              class="ml-1"
              v-model="selected_sort"
              :options="selected_sort_options"
              @change="doSortAction"
            >
            </b-form-select>
          </div>
        </b-form>
      </b-card>

      <div class="d-flex justify-content-center mb-1" v-if="loading">
        <b-spinner class="float-right" label="Floated Right" />
      </div>
      <br />
      <!-- Search Form ends here-->
      <div class="row my-2 align-items-center">
        <div class="col-3">
          <div v-if="!loading" class="clearfix">
            {{ total_vulnerabilties_count }} Vulnerabilities
          </div>
        </div>
        <div class="col-9 d-flex justify-content-end">
          <div
            class="mr-1 d-flex justify-content-end align-items-center"
            style="width: 35%"
          >
            <span class="mr-1">Per page</span>
            <b-form-select
              class=""
              size="sm"
              style="width: 30%"
              v-model="perPage"
              :options="page_filter_options"
              @change="searchFn()"
            >
            </b-form-select>
          </div>
          <b-button
            variant="warning"
            size="sm"
            class="mr-1"
            @click="openMitigateModal()"
          >
            <span
              ><feather-icon
                icon="ExternalLinkIcon"
                class="mr-50"
              />Mitigate</span
            >
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="mr-1"
            @click="loadAssignVuln()"
          >
            <span
              ><feather-icon icon="UserCheckIcon" class="mr-50" />Assign
              Vulnerability</span
            >
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="requestForRetest()"
          >
            <span>Request for Retest</span>
          </b-button>
          <b-button variant="primary" size="sm" @click="getCSV()">
            <span v-if="isloadingCsv"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else
              ><feather-icon icon="DownloadIcon" class="mr-50" />Export to
              CSV</span
            >
          </b-button>
        </div>
      </div>
      <div v-if="!loading">
        <b-table
          responsive
          id="vulnTable"
          :fields="visibleFields"
          :items="items"
          :per-page="perPage"
          v-if="items.length"
          hover
        >
          <template v-slot:head(cve_meta_data)>
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis">Remediation Priority</span>
              <div class="d-flex flex-column">
                <feather-icon
                  icon="ChevronUpIcon"
                  class="mr-50"
                  @click="setSort(true)"
                  style="height: 10px"
                />
                <feather-icon
                  icon="ChevronDownIcon"
                  class="mr-50"
                  @click="setSort(false)"
                  style="height: 10px"
                />
              </div>
            </div>
          </template>
          <template v-slot:head(is_assigned)>
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis">Assigned For Retest</span>
            </div>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>

          <template #cell(selected_vulns)="data">
            <b-form-checkbox
              name="selected-items"
              v-model="selectedVulns"
              :value="data.item.id"
              @change="updateCheckAll()"
            >
            </b-form-checkbox>
          </template>

          <!-- <template #cell(name)="data">
              <a class="text-truncate" @click="showSidebar(data)">
                <del v-if="data.item.status == 1">{{ data.item.name }}</del>
                <span v-else>{{ truncateName(data.item.name) }}</span>
              </a>
            </template> -->
          <template #cell(name)="data">
            <a class="text-truncate" @click="showSidebar(data)">
              <del
                v-if="data.item.status == 1"
                v-html="breakTextIntoLines(data.item.name)"
              >
              </del>
              <span v-else v-html="breakTextIntoLines(data.item.name)"></span>
            </a>
          </template>
          <template #cell(cve_meta_data)="data">
            <b-badge
              style="background-color: #991d28"
              v-if="data.item.cve_meta_data == 1"
              >Critical</b-badge
            >
            <b-badge variant="danger" v-if="data.item.cve_meta_data == 2"
              >High</b-badge
            >
            <b-badge variant="warning" v-if="data.item.cve_meta_data == 3"
              >Medium</b-badge
            >
            <b-badge variant="success" v-if="data.item.cve_meta_data == 4"
              >Low</b-badge
            >
            <b-badge variant="info" v-if="data.item.cve_meta_data == 5"
              >Very Low</b-badge
            >
            <b-badge
              variant="light-danger"
              v-if="data.item.cve_meta_data == null"
              >NA</b-badge
            >

            <div class="mt-1">
              <span class="d-flex" style="front-size: 2px">
                <span class="mr-1" style="font-size: 10px">CISA KEV:</span>
                <b-badge
                  variant="success"
                  style="font-size: 8px"
                  v-if="data.item.cisa_kev == true"
                  >Yes</b-badge
                >
                <b-badge
                  variant="danger"
                  v-if="data.item.cisa_kev == false"
                  style="font-size: 8px"
                  >No</b-badge
                >
              </span>
              <span class="d-flex">
                <span style="font-size: 10px" class="mr-1">EPSS:</span>
                <span style="font-size: 10px">{{ data.item.epss }}</span>
              </span>
            </div>
          </template>

          <template #cell(created_at)="data">
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis" class="text-capitalize">{{
                data.item.created_at | formatdate
              }}</span>
            </div>
          </template>
          <template #cell(asset)="data">
            <a
              class="text-truncate"
              @click="redirectToAssetScan(data.item.asset.asset_id)"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              v-b-tooltip.hover.v-dark
              :title="data.item.asset.asset_name"
            >
              <span>
                {{
                  isExpanded(data.item.asset.asset_id)
                    ? data.item.asset.asset_name
                    : truncateName(data.item.asset.asset_name)
                }}
              </span>
            </a>
            <a
              v-if="data.item.asset.asset_name.length > 30"
              @click="toggleExpand(data)"
              size="sm"
              variant="outline-primary"
            >
              <span style="font-size: 12px !important">{{
                isExpanded(data.item.asset.asset_id) ? " show less" : "..."
              }}</span></a
            >
          </template>

          <template #cell(is_verified)="data">
            <b-avatar v-if="data.item.is_verified" variant="success">
              <feather-icon icon="ThumbsUpIcon" />
            </b-avatar>
            <b-avatar v-else variant="danger">
              <feather-icon icon="ThumbsDownIcon" />
            </b-avatar>
          </template>
          <!-- #cell(severity)="data" -->
          <template v-slot:cell(severity)="data">
            <b-badge
              style="background-color: #991d28"
              v-if="data.item.severity == 'critical'"
              >Critical</b-badge
            >
            <b-badge variant="danger" v-else-if="data.item.severity == 'high'"
              >High</b-badge
            >
            <b-badge
              variant="warning"
              v-else-if="data.item.severity == 'medium'"
              >Medium</b-badge
            >
            <b-badge variant="primary" v-else-if="data.item.severity == 'low'"
              >Low</b-badge
            >
            <b-badge variant="success" v-else-if="data.item.severity == 'info'"
              >Info</b-badge
            >
            <b-badge variant="secondary" v-else>Unknown</b-badge>
          </template>
          <!-- #cell(status)="data" -->
          <template v-slot:cell(status)="data">
            <b-badge variant="danger" v-if="data.item.status == 0"
              >Open</b-badge
            >
            <b-badge variant="success" v-if="data.item.status == 1"
              >Closed</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 2"
              >False Positive</b-badge
            >
            <b-badge variant="success" v-if="data.item.status == 3"
              >Accepted</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 4"
              >Assigned</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 5"
              >Mitigated</b-badge
            >
          </template>
          <!-- #cell(is_assigned)="data" -->
          <template v-slot:cell(is_assigned)="data">
            <div class="d-flex justify-content-center">
              <a class="text-primary" @click="showSidebarTask(data)">
                <b-badge variant="success" v-if="data.item.is_assigned == true"
                  >Yes</b-badge
                >
                <b-badge variant="danger" v-else>No</b-badge>
              </a>
            </div>
          </template>
          <template v-slot:cell(is_exploited)="data">
            <b-badge variant="success" v-if="data.item.is_exploited == true"
              >Yes</b-badge
            >
            <b-badge variant="danger" v-else>No</b-badge>
          </template>
          <template v-slot:cell(protocol)="data">
            <b-badge variant="primary" v-if="data.item.protocol == 'tcp'"
              >TCP</b-badge
            >
            <b-badge variant="info" v-if="data.item.protocol == 'udp'"
              >UDP</b-badge
            >
          </template>
          <template #cell(updated_at)="data">
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis" class="text-capitalize">{{
                data.item.updated_at | formatdate
              }}</span>
            </div>
          </template>
        </b-table>
        <div v-else>
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-4"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Data Found</h3>
          </div>
        </div>
      </div>

      <b-pagination-nav
        v-model="currentPage"
        :number-of-pages="total"
        :link-gen="linkGen"
        :total-rows="rows"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="vulnTable"
        use-router
        v-if="!loading"
      />

      <b-sidebar
        id="sidebar-task-handler"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        width="50%"
      >
        <!-- Header -->
        <VulnDetail
          :vuln="vuln"
          :callBack="searchFn"
          :closeSidebar="closeSidebar"
          :asset_id="asset_id"
          :organization="organization_id"
          @div-id="handleDivId"
          :activeTab="activeTab"
        />
        <!-- <div id="myDiv">
        <h1>hello</h1>
      </div> -->
      </b-sidebar>
      <b-sidebar
        id="sidebar-task-handler-taskform"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        width="50%"
      >
        <!-- Header -->

        <TaskForm
          :vulnerabilties="selectedVulns"
          :callBack="searchFn"
          :organization="organization_id"
          :users="users"
          :closeSidebar="closeSidebarTaskform"
          :filter="filter"
          :isCheckAllFilterResults="isCheckAllFilterResults"
        />
      </b-sidebar>
      <b-modal
        ref="modal-bulk-delete"
        modal-class="modal-danger"
        centered
        title="Delete.. !"
        hide-footer
        no-close-on-backdrop
        @hidden="closeModal"
        size="lg"
      >
        <b-card-text>
          Do you really want to delete the selected vulnerabilities ?
        </b-card-text>
        <div style="margin-top: 10px">
          <span class="font-italic"
            >This action will remove the following associated records:
          </span>
          <ul class="font-italic">
            <li>Tasks</li>
          </ul>
        </div>
        <div class="d-flex justify-content-end">
          <b-button @click="changeStatus(9)" variant="danger" size="sm">
            Delete
          </b-button>
        </div>
      </b-modal>
      <b-modal
        ref="modal-assign-vuln"
        title="Assign Vulnerabilities"
        hide-footer
        no-close-on-backdrop
        @hidden="closeAssignModal"
        size="lg"
      >
        <AssignVuln
          :selectedVulns="selectedVulns"
          :filter="filter"
          :organization="organization"
          :parentCallBack="closeAssignModal"
          :isCheckAllFilterResults="isCheckAllFilterResults"
        />
      </b-modal>
      <b-modal
        ref="modal-retest-vuln"
        title="Request for Retest"
        hide-footer
        no-close-on-backdrop
        @hidden="closeRequestforRetest"
        size="lg"
      >
        <b-card-text> Do you really want to request for retest? </b-card-text>
        <div class="d-flex justify-content-end mt-1">
          <b-button
            @click="secusyServiceBulkAction(14)"
            variant="warning"
            size="sm"
          >
            Request for Retest
          </b-button>
        </div>
      </b-modal>
    </b-card-code>
  </div>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BSidebar,
  VBToggle,
  BBadge,
  BFormInput,
  BRow,
  BFormSelect,
  BJumbotron,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BAvatar,
  BDropdown,
  BDropdownForm,
  BDropdownItem,
  BSpinner,
  BTabs,
  BTab,
  VBTooltip,
} from "bootstrap-vue";
import VulnDetail from "../../Asset/components/vuln_details.vue";
import TaskForm from "../components/mitigate_bulk.vue";
import AssignVuln from "../components/assignVuln.vue";
// import GroupedVuln from "./groupedVuln/groupedVulnList.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BSpinner,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BSidebar,
    VBToggle,
    VulnDetail,
    BBadge,
    BForm,
    BFormInput,
    BFormCheckbox,
    BRow,
    BFormSelect,
    BJumbotron,
    BFormGroup,
    vSelect,
    TaskForm,
    BAvatar,
    BDropdown,
    BDropdownForm,
    BDropdownItem,
    flatPickr,
    BTabs,
    BTab,
    AssignVuln,
    // GroupedVuln,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      query: false,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 20,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#", visible: true },
        { key: "selected_vulns", label: "select", visible: true },
        { key: "name", label: "Name", sortable: true, visible: true },
        { key: "cve_meta_data", label: "Remediation Priority", visible: true },
        { key: "severity", label: "Severity", visible: true },
        { key: "status", label: "Status", visible: true },
        { key: "asset", label: "Asset", visible: true },
        {
          key: "asset.asset_nickname",
          label: "Nick Name",
          sortable: true,
          visible: false,
        },
        { key: "port", label: "Port", visible: true },
        { key: "protocol", label: "Protocol", visible: false },
        { key: "is_verified", label: "Verified", visible: true },
        { key: "is_assigned", label: "Assigned For Retest", visible: true },
        { key: "is_exploited", label: "Exploited", visible: false },
        { key: "created_at", label: "Added At", visible: false },
        { key: "cvss_score", label: "CVSS Score", visible: false },
        { key: "cve", label: "CVE", visible: false },
        { key: "path", label: "Path", visible: false },
        { key: "parameter", label: "Parameter", visible: false },
        { key: "updated_at", label: "Updated At", visible: false },
        { key: "source", label: "Source", visible: false },
        // { key: "actions", label: "Actions" },
      ],
      asset_id: 0,
      organization_id: 0,
      vuln: {},
      is_assigned: null,
      assigned_filter_options: [
        { value: null, text: "--Is Assigned?--" },
        { value: false, text: "No" },
        { value: "mitigation", text: "Assigned for Mitigation" },
        { value: "retest", text: "Assigned for Retest" },
      ],
      // severity_filter: null,
      severity_filter: [],
      severity_filter_options: [
        // { value: null, text: "--Select Severity--" },
        { value: "info", text: "Info" },
        { value: "low", text: "Low" },
        { value: "medium", text: "Medium" },
        { value: "high", text: "High" },
        { value: "critical", text: "Critical" },
      ],
      name_filter: "",
      asset_filter: null,
      asset_options: [],
      // status_filter: null,
      status_filter: [],
      status_filter_options: [
        // { value: null, text: "--Select Status--" },
        { value: 0, text: "Open" },
        { value: 1, text: "Closed" },
        { value: 2, text: "False Positive" },
        { value: 3, text: "Accepted" },
        // { value: 4, text: "Assigned" },
        { value: 5, text: "Mitigated" },
      ],
      // group_filter: null,
      // group_filter_options: [{ value: null, text: "--Select Group--" }],
      group_filter: [],
      group_filter_options: [],
      port_filter: "",
      port_type: null,
      port_type_options: [
        { value: null, text: "Protocol Type" },
        { value: "tcp", text: "TCP" },
        { value: "udp", text: "UDP" },
      ],
      selectedVulns: [],
      with_selected_options: [
        { value: null, text: "--Actions--" },
        // { value: "export_to_csv", text: "Export to CSV" },
        // { value: "mitigate", text: "Mitigate" },
        // { value: "mark_as_closed", text: "Mark as Closed" },
        // { value: "mark_as_open", text: "Mark as Open" },
        // { value: "mark_as_false_positive", text: "Mark as False Positive" },
        { value: "accept", text: "Accept the Risk" },
        { value: "delete", text: "Delete" },
        // { value: "mark_as_verified", text: "Mark as verified" },
        // { value: "mark_as_unverified", text: "Mark as unverified" },
        // { value: "mark_as_exploited", text: "Mark as exploited" },
        // { value: "mark_as_unexploited", text: "Mark as unexploited" },
        // { value: "assign_vuln", text: "Assign Vulnerability" },
      ],
      vuln_with_selected_options: [
        { value: null, text: "--Vulnerability Actions--" },
        { value: "mark_as_closed", text: "Mark as Closed" },
        { value: "mark_as_open", text: "Mark as Open" },
        { value: "mark_as_false_positive", text: "Mark as False Positive" },
        { value: "mark_as_verified", text: "Mark as verified" },
        { value: "mark_as_unverified", text: "Mark as unverified" },
        { value: "mark_as_exploited", text: "Mark as exploited" },
        { value: "mark_as_unexploited", text: "Mark as unexploited" },
      ],
      selected_sort_options: [
        { value: null, text: "--Sort With--" },
        { value: "updated_at", text: "Last Updated" },
        { value: "added_at", text: "Added At" },
      ],
      selected_sort: null,

      is_verified: null,
      verified_filter_options: [
        { value: null, text: "--Is Verified?--" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      is_exploited: null,
      exploited_filter_options: [
        { value: null, text: "--Is Exploited?--" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],

      selected_action: null,
      isCheckAllFilterResults: false,
      isCheckAll: false,
      is_staff: this.$store.state.app.user.is_staff,
      // organization: this.$store.state.app.org_id,
      // organization : this.$store.state.app.user.profile.organization,
      organization: null,
      organizations: [],
      users: [{ value: null, text: "--Select User--" }],
      filter: [],
      is_search_on: false,
      total_vulnerabilties_count: 0,
      loading: false,
      label_options: [],
      label_filter: [],
      multi_asset_filter: [],
      multi_asset_options: [],
      // rangeDate: null,
      fromDate: null,
      endDate: null,
      assetname_filter: "",

      isloading: false,
      isloadingCsv: false,

      isHidden: false,

      divId: "",
      activeTab: "",
      currentStatus: this.$route.query.status,
      currentSeverity: this.$route.query.severity,
      currentGroup: this.$route.query.groupid,

      clickedName: "",
      tabIndex: 0,
      name: "",
      page_filter_options: [
        { value: "10", text: "10" },
        { value: "20", text: "20" },
        { value: "50", text: "50" },
        { value: "100", text: "100" },
        { value: "500", text: "500" },
      ],
      isRemediation: false,
      remediation_filter: [],
      remediation_filter_options: [
        // { value: null, text: "--Select Remediation Priority--" },
        { value: 5, text: "Very Low" },
        { value: 4, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 2, text: "High" },
        { value: 1, text: "Critical" },
      ],
      expandedItems: [],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    filteredOptions() {
      if (this.$store.state.app.user.is_staff) {
        // If user is staff, show all options
        return this.vuln_with_selected_options;
      } else {
        // If user is not staff, filter out "Mark as Verified" option
        return this.vuln_with_selected_options.filter(
          (option) =>
            option.value !== "mark_as_verified" &&
            option.value !== "mark_as_unverified"
        );
      }
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
    } else {
      this.name = this.$route.query.vulnName;
    }

    const queryStatus = this.$route.query.status;
    if (queryStatus) {
      const statusValues = Array.isArray(queryStatus)
        ? queryStatus.map(Number)
        : [Number(queryStatus)];
      this.status_filter = this.status_filter_options
        .filter((option) => statusValues.includes(option.value))
        .map((option) => option.value);
    }

    const querySeverity = this.$route.query.severity;
    if (querySeverity) {
      const severityValues = Array.isArray(querySeverity)
        ? querySeverity
        : [querySeverity];
      this.severity_filter = this.severity_filter_options
        .filter((option) => severityValues.includes(option.value))
        .map((option) => option.value);
    }

    this.load();
    this.searchFn();
    if (this.$route.query.group_id) {
      this.loadGroupsAndSearch();
    }
    if (this.$route.query.asset_id) {
      this.loadAsset();
    }
    this.fetchLabels();
  },
  watch: {
    perPage(newVal, oldVal) {
      this.$router.replace({ query: { page: 1 } });
      this.currentPage = 1; // Reset currentPage to 1 when perPage changes
      this.searchFn();
    },
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);

      // this.isCheckAll = false
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    breakTextIntoLines(text) {
      const maxCharacters = 50;
      const words = text.split(/\s+/);
      const lines = [];

      let currentLine = "";

      for (const word of words) {
        if ((currentLine + word).length <= maxCharacters) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines.join("<br>");
    },
    toggleExpand(data) {
      const index = this.expandedItems.indexOf(data.item.id);
      if (index === -1) {
        this.expandedItems.push(data.item.id);
      } else {
        this.expandedItems.splice(index, 1);
      }
    },
    isExpanded(itemId) {
      return this.expandedItems.includes(itemId);
    },
    truncateName(name) {
      if (name.length > 30) {
        return name.slice(0, 30);
      }
      return name;
    },
    handleRowClick(name) {
      this.clickedName = name;
      this.tabIndex = 1;
      this.searchFn();
    },
    setSort(sortValue) {
      this.isRemediation = sortValue;
      this.searchFn();
    },
    refreshFn() {
      this.name_filter = "";
      this.group_filter = [];
      this.severity_filter = [];
      this.status_filter = [];
      this.multi_asset_filter = [];
      this.port_type = null;
      this.port_filter = "";
      this.is_verified = null;
      this.is_exploited = null;
      this.is_assigned = null;
      this.label_filter = [];
      this.remediation_filter = [];
      this.fromDate = null;
      this.endDate = null;
      (this.assetname_filter = ""), this.searchFn();
    },
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    linkGen: function (pageNum) {
      let current_path = this.$router.currentRoute.path + "?page=" + pageNum;
      return current_path;
    },
    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      this.users = [];

      if (this.is_staff) {
        this.loading = true;
        this.organization = this.$store.state.app.org_id;
        // const o_options = {
        //   method: "GET",
        //   headers: { "content-type": "application/json" },
        //   url:
        //     process.env.VUE_APP_BASEURL +
        //     "organization/organization/get-all-org",
        // };
        // var self = this;
        // this.$http(o_options).then((res) => {
        //   res.data.map(function (value, key) {
        //     let org = {
        //       value: res.data[key].org_id,
        //       text: res.data[key].org_name,
        //     };
        //     self.organizations.push(org);
        //     self.loading = false;
        //   });
        // });
      } else {
        this.users = [];
        this.loading = true;
        // this.organization = this.$store.state.app.user.profile.organization;
        this.organization = this.$store.state.app.org_id;
        /////////// load vulnerabilities /////////////
        // let url =
        //   process.env.VUE_APP_BASEURL + "asset/vulns?page=" + this.currentPage;
        // if (this.$route.query.status == "0") {
        //   debugger;
        //   url = url + "&status=0";
        // }
        // const options = {
        //   method: "GET",
        //   headers: { "content-type": "application/json" },
        //   url: url,
        // };
        // this.$http(options).then((res) => {
        //   this.items = res.data.results;
        //   this.rows = res.data.length;
        //   this.total = Math.ceil(res.data.count / this.perPage);
        //   this.total_vulnerabilties_count = res.data.count;
        //   this.loading = false;
        // });
        //////////// Load Asset Groups ////////////////
        // const a_options = {
        //   method: "GET",
        //   headers: { "content-type": "application/json" },
        //   url: process.env.VUE_APP_BASEURL + "asset/group",
        // };
        // var self = this;
        // this.$http(a_options).then((res) => {
        //   res.data.map(function (value, key) {
        //     let a = {
        //       value: res.data[key].group_id,
        //       text: res.data[key].group_name,
        //     };
        //     self.group_filter_options.push(a);
        //   });
        // });

        //////////// Load users for task assignment ///////////

        // const o_options = {
        //   method: "GET",
        //   headers: { "content-type": "application/json" },
        //   url: process.env.VUE_APP_BASEURL + "user/users/get-all-users",
        // };
        // var self = this;
        // this.$http(o_options).then((res) => {

        //   res.data.map(function (value, key) {
        //     let user = {
        //       value: res.data[key].id,
        //       text: res.data[key].username,
        //     };

        //     self.users.push(user);
        //   });
        // });
      }
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text:
              res.data[key].full_name.charAt(0).toUpperCase() +
              res.data[key].full_name.slice(1),
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
      });

      //}
      //////////////////////////////////////////////////////
    },
    fetchGroup: function (search) {
      if (search.length > 0) {
        // this.users = [];

        if (this.organization == null) {
          this.filter = [];
          this.is_search_on = false;
          this.items = [];
          return;
        }
        // if(this.group_filter != null){
        //   url = url_asset + "&group_id=" + this.group_filter;
        // }

        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/group/list-all-asset-groups?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.group_filter_options = [];
          res.data.map(function (value, key) {
            let a = {
              value: res.data[key].group_id,
              text: res.data[key].group_name,
            };
            self.group_filter_options.push(a);
          });
          const queryGroup = this.$route.query.group_id;
          if (queryGroup) {
            const groupValues = Array.isArray(queryGroup)
              ? queryGroup.map(Number)
              : [Number(queryGroup)];
            this.group_filter = this.group_filter_options
              .filter((option) => groupValues.includes(option.value))
              .map((option) => option.value);
          }
        });
      }
    },
    loadGroupsAndSearch: function () {
      // this.users = [];

      if (this.organization == null) {
        this.filter = [];
        this.is_search_on = false;
        this.items = [];
        return;
      }

      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group/list-all-asset-groups?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        this.group_filter_options = [];
        res.data.map(function (value, key) {
          let a = {
            value: res.data[key].group_id,
            text: res.data[key].group_name,
          };
          self.group_filter_options.push(a);
        });
        const queryGroup = this.$route.query.group_id;
        if (queryGroup) {
          const groupValues = Array.isArray(queryGroup)
            ? queryGroup.map(Number)
            : [Number(queryGroup)];
          this.group_filter = this.group_filter_options
            .filter((option) => groupValues.includes(option.value))
            .map((option) => option.value);
        }
      });
      // Load users
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].username,
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
      });

      // // load multi asset
      // const options = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   // url:
      //   //   process.env.VUE_APP_BASEURL +
      //   //   "asset/asset?org_id=" +
      //   //   this.organization,
      //   url:
      //     process.env.VUE_APP_BASEURL +
      //     "asset/org-asset/list-all-org-assets?org_id=" +
      //     this.organization,
      // };
      // var self = this;
      // this.$http(options).then((res) => {
      //   res.data.map(function (value, key) {
      //     // let a = {
      //     //   value: res.data.results[key].asset_id,
      //     //   text: res.data.results[key].asset_name,
      //     // };
      //     let a = {
      //       value: res.data[key].asset_id,
      //       text: res.data[key].asset_name,
      //     };
      //     // console.log(res.data[key].profile.organization)
      //     self.multi_asset_options.push(a);
      //   });
      // });

      //Then search
      // this.searchFn();
    },
    loadAsset: function () {
      this.multi_asset_options = [];
      // this.loading = true;
      // loading(true);
      // Load assets here
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        self.multi_asset_options = [];
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].asset_id,
            text:
              res.data.results[key].asset_nickname +
              " (" +
              res.data.results[key].assetType.asset_type_name +
              ")",
          };
          // console.log(res.data[key].profile.organization)
          self.multi_asset_options.push(a);
          // self.loading = false;
        });
        const queryAsset = this.$route.query.asset_id;
        if (queryAsset) {
          const assetValues = Array.isArray(queryAsset)
            ? queryAsset.map(Number)
            : [Number(queryAsset)];
          this.multi_asset_filter = this.multi_asset_options
            .filter((option) => assetValues.includes(option.value))
            .map((option) => option.value);
        }
      });
    },

    showSidebar: function (data) {
      this.vuln = data.item;
      this.asset_id = data.item.asset.asset_id;
      // this.organization_id = data.item.asset.assetOwner.profile.organization;
      this.organization_id = this.$store.state.app.org_id;
      this.activeTab = 0;
      console.log("vuln", data);
      // this.$refs['taskSideBar'].show();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
      // this.scrollTo();
    },

    showSidebarTask: function (data) {
      this.vuln = data.item;
      this.asset_id = data.item.asset.asset_id;
      // this.organization_id = data.item.asset.assetOwner.profile.organization;
      this.organization_id = this.$store.state.app.org_id;
      this.activeTab = 2;
      console.log("vuln", data);
      // this.$refs['taskSideBar'].show();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
      this.scrollTo();
    },
    handleDivId(id) {
      this.divId = id;
    },
    // side bar scroll function
    scrollTo() {
      // this.$nextTick(() => this.$refs.showTaskSection.scrollIntoView({ behavior: 'smooth' }));
      this.$nextTick(() =>
        document.getElementById("myDiv").scrollIntoView({ behavior: "smooth" })
      );
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    closeSidebarTaskform: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler-taskform");
      this.selected_action = null;
    },
    doSortAction: function () {
      this.filter = [];
      let url = process.env.VUE_APP_BASEURL + "asset/vulns?query=true";
      if (this.selected_sort == "added_at") {
        url = url + "&added_at=" + this.selected_sort;
        this.filter.push({ created_at: this.selected_sort });
      }
      if (this.selected_sort == "updated_at") {
        url = url + "& updated_at=" + this.selected_sort;
        this.filter.push({ updated_at: this.selected_sort });
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        this.items = res.data.results;
        //setInterval(function(){self.updateCheckAll()},3000);
        this.updateCheckAll();
        this.loading = false;
      });
    },
    searchFn: function (reset = true) {
      this.isloading = true;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/vulns?query=true&page=" +
        this.currentPage +
        "&items_per_page=" +
        this.perPage +
        "&closed=1";
      // +
      // "&org_id=" +
      // this.organization;
      // if (this.$route.query.status && this.status_filter.length == 0) {
      //   debugger
      //   url = url + `&${queryString}`;
      //     const queryString = this.currentStatus
      //     .map((item) => `status=${item}`)
      //     .join("&");
      //   url = url + `&${queryString}`;
      //   this.filter.push({ status: this.currentStatus });
      // }
      if (this.isRemediation) {
        url = url + "&cve_priority=" + this.isRemediation;
      }
      if (this.$route.query.fromDate && this.$route.query.endDate) {
        this.isHidden = true;
        this.fromDate = this.$route.query.fromDate;
        this.endDate = this.$route.query.endDate;
        url = url + "&from_date=" + this.fromDate + "&end_date=" + this.endDate;
        this.filter.push({ from_date: this.fromDate, end_date: this.endDate });
      }
      if (this.$route.query.status && this.status_filter.length === 0) {
        const queryString = this.$route.query.status
          .map((item) => `status=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        this.status_filter = this.$route.query.status;
        this.filter.push({ status: this.currentStatus });
      }
      if (this.$route.query.asset_id && this.multi_asset_filter.length === 0) {
        const asset_id = this.$route.query.asset_id;
        url = url + `&asset_ids=${asset_id}`;
        this.multi_asset_filter = this.$route.query.asset_id;
        this.filter.push({ asset_id: this.$route.query.asset_id });
      }
      if (this.$route.query.name && this.name_filter == "") {
        url = url + "&name=" + this.name;
        this.name_filter = this.name;
        this.filter.push({ name: this.name });
      }

      if (this.$route.query.vulnName && this.name_filter == "") {
        url = url + "&exact_name=" + this.name;
        this.name_filter = this.name;
        this.filter.push({ name: this.name });
      }

      if (this.$route.query.is_exploited && this.is_exploited === null) {
        // this.isHidden = true;
        this.is_exploited = this.$route.query.is_exploited;
        url = url + "&exploited=" + this.is_exploited;
        this.filter.push({ is_exploited: this.is_exploited });
      }

      // -------test groupfilter group not showing but filtering
      // if (this.$route.query.group_id && this.group_filter.length === 0) {
      //   const groupId = parseInt(this.$route.query.group_id);
      //   url = url + `&group_id=${groupId}`;

      //   const selectedGroup = this.group_filter_options.find(
      //     (option) => option.value === groupId
      //   );
      //   if (selectedGroup) {
      //     this.group_filter = [selectedGroup];
      //     this.filter.push({ group_id: groupId });
      //   }
      // }
      // ------------------------------
      // if (this.$route.query.severity && this.severity_filter.length === 0) {
      //   url = url + `&severity=${this.$route.query.severity}`;
      //   this.severity_filter = this.$route.query.severity;
      //   this.filter.push({ severity: this.$route.query.severity });
      // }
      if (this.$route.query.group_id && this.group_filter.length === 0) {
        const groupId = this.$route.query.group_id;
        url = url + `&group_id=${groupId}`;
        this.group_filter = this.$route.query.group_id;
        this.filter.push({ group_id: this.$route.query.group_id });
      }
      if (this.$route.query.severity && this.severity_filter == null) {
        const queryString = this.$route.query.severity
          .map((item) => `severity=${item}`)
          .join("&");
        url = url + `&severity=` + `&${queryString}`;
        this.severity_filter = this.$route.query.severity; // Set selected filter values
        this.filter.push({ severity: this.severity_filter });
      }

      // vuln dashboard route
      // if (
      //   this.$route.query.severity == "critical" &&
      //   this.severity_filter.length == 0
      // ) {
      //   url = url + "&severity=critical&status=0&status=3&status=5";
      // }
      // if (
      //   this.$route.query.severity == "high" &&
      //   this.severity_filter.length == 0
      // ) {
      //   url = url + "&severity=high&status=0&status=3&status=5";
      // }
      // if (
      //   this.$route.query.severity == "medium" &&
      //   this.severity_filter.length == 0
      // ) {
      //   url = url + "&severity=medium&status=0&status=3&status=5";
      // }
      // if (
      //   this.$route.query.severity == "low" &&
      //   this.severity_filter.length == 0
      // ) {
      //   url = url + "&severity=low&status=0&status=3&status=5";
      // }
      // if (
      //   this.$route.query.severity == "info" &&
      //   this.severity_filter.length == 0
      // ) {
      //   url = url + "&severity=info&status=0&status=3&status=5";
      // }

      // if (this.organization != null) {
      //   url = url + "&org_id=" + this.organization;
      //   this.filter.push({ organization: this.organization });
      // }
      if (this.$route.query.name == "Insecure port" && this.name_filter == "") {
        url = url + "&name=Insecure port";
      }
      if (this.clickedName != "" && this.name_filter == "") {
        url = url + "&name=" + this.clickedName;
        this.name_filter = this.clickedName;
        this.filter.push({ name: this.clickedName });
      }
      if (this.name_filter != "") {
        url = url + "&name=" + this.name_filter;
        this.filter.push({ name: this.name_filter });
      }

      if (this.assetname_filter != "") {
        url = url + "&asset_name=" + this.assetname_filter;
        this.filter.push({ asset_name: this.assetname_filter });
      }

      // if (this.severity_filter != null) {
      //   url = url + "&severity=" + this.severity_filter;
      //   this.filter.push({ severity: this.severity_filter });
      // }

      // if (this.severity_filter.length != 0 ) {

      //   const queryString = this.severity_filter
      //     .map((item) => `severity=${item}`)
      //     .join("&");
      //   url = url + `&${queryString}`;
      //   this.filter.push({ severity: this.severity_filter });
      // }
      // ------------------------------
      // if (
      //   Array.isArray(this.severity_filter) &&
      //   this.severity_filter.length !== 0
      // ) {
      //   const queryString = this.severity_filter
      //     .map((item) => `severity=${item}`)
      //     .join("&");
      //   url = url + `&${queryString}`;
      //   this.filter.push({ severity: this.severity_filter });
      // }

      if (this.severity_filter.length !== null) {
        const queryString = this.severity_filter
          .map((item) => `severity=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        this.filter.push({ severity: this.severity_filter });
      }

      if (this.remediation_filter.length !== null) {
        const queryString = this.remediation_filter
          .map((item) => `remediation_priority=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        this.filter.push({ remediation_priority: this.remediation_filter });
      }

      if (this.port_filter != "") {
        url = url + "&port=" + this.port_filter;
        this.filter.push({ port: this.port_filter });
      }
      if (this.port_type != null) {
        url = url + "&protocol=" + this.port_type;
        this.filter.push({ port_type: this.port_type });
      }
      if (this.asset_filter != null) {
        url = url + "&asset_id=" + this.asset_filter.value;
        this.filter.push({ asset_id: this.asset_filter.value });
      }

      if (this.multi_asset_filter.length != 0 && !this.$route.query.asset_id) {
        const queryString = this.multi_asset_filter
          .map((item) => `asset_ids=${item.value}`)
          .join("&");
        url = url + `&${queryString}`;
        // url = url + "&asset_ids=" + this.multi_asset_filter;
        this.filter.push({ asset_ids: this.multi_asset_filter });
      }

      if (this.is_exploited != null && !this.$route.query.is_exploited) {
        url = url + "&exploited=" + this.is_exploited;
        this.filter.push({ is_exploited: this.is_exploited });
      }

      // if (this.status_filter != null) {
      //   url = url + "&status=" + this.status_filter;
      //   this.filter.push({ status: this.status_filter });
      // }

      if (this.status_filter.length != 0) {
        // url = url + "&status=" + this.status_filter;
        // this.filter.push({ status: this.status_filter });

        const queryString = this.status_filter
          .map((item) => `status=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        this.filter.push({ status: this.status_filter });
      }
      if (this.fromDate != null && this.endDate == null) {
        url = url + "&from_date=" + this.fromDate;
        this.filter.push({ from_date: this.fromDate });
      }
      if (
        this.fromDate != null &&
        this.endDate != null &&
        !this.$route.query.fromDate
      ) {
        // debugger
        url = url + "&from_date=" + this.fromDate + "&end_date=" + this.endDate;
        this.filter.push({ from_date: this.fromDate, end_date: this.endDate });
      }
      // if (this.group_filter != null) {
      //   url = url + "&group_id=" + this.group_filter;
      //   this.filter.push({ group_id: this.group_filter });
      // }
      if (this.group_filter.length != 0 && !this.$route.query.group_id) {
        // url = url + "&group_id=" + this.group_filter;
        // this.filter.push({ group_id: this.group_filter });

        const queryString = this.group_filter
          .map((item) => `group_id=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        this.filter.push({ group_id: this.group_filter });
      }
      if (this.is_assigned != null) {
        url = url + "&assigned=" + this.is_assigned;
        this.filter.push({ is_assigned: this.is_assigned });
      }

      if (this.label_filter.length != 0) {
        url = url + "&labels=" + this.label_filter;
        this.filter.push({ labels: this.label_filter });
      }
      // if (this.label_filter.length != 0) {
      //   let label_filter_string = "";
      //   Object.keys(this.label_filter).forEach((key) => {
      //     console.log(key);        // the name of the current key.
      //     label_filter_string += this.label_filter[key].text + ","; // the value of the current key.
      //   });
      //   url = url + "&labels=" + label_filter_string;
      //   this.filter.push({ labels: label_filter_string });
      // }
      // new filters
      if (this.is_verified != null) {
        url = url + "&verified=" + this.is_verified;
        this.filter.push({ is_verified: this.is_verified });
      }
      if (this.is_exploited != null) {
        url = url + "&exploited=" + this.is_exploited;
        this.filter.push({ is_exploited: this.is_exploited });
      }

      if (this.filter.length) this.is_search_on = true;

      this.query = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.total_vulnerabilties_count = res.data.count;
        //setInterval(function(){self.updateCheckAll()},3000);
        this.updateCheckAll();
        this.loading = false;
        this.isloading = false;
      });
    },
    // async sendRequest() {
    //   const list = [1, 2, 3];
    //   const queryString = list.map((item) => `items=${item}`).join("&");
    //   const response = await axios.get(
    //     `https://example.com/api?${queryString}`
    //   );
    //   console.log(response.data);
    // },
    fetchOptions: function (search, loading) {
      if (search.length > 2) {
        this.multi_asset_options = [];
        this.loading = true;
        // loading(true);
        // Load assets here
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.multi_asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].assetType.asset_type_name +
                ")",
            };
            // console.log(res.data[key].profile.organization)
            self.multi_asset_options.push(a);
            self.loading = false;
          });
          const queryAsset = this.$route.query.asset_id;
          if (queryAsset) {
            const assetValues = Array.isArray(queryAsset)
              ? queryAsset.map(Number)
              : [Number(queryAsset)];
            this.multi_asset_filter = this.multi_asset_options
              .filter((option) => assetValues.includes(option.value))
              .map((option) => option.value);
          }
        });
      }
    },

    fetchLabels: function (search, loading) {
      // if (search.length) {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/vuln-label?query=true",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        self.label_options = [];
        res.data.map(function (value, key) {
          let a = {
            value: value.text,
            text: value.text,
          };
          // console.log(res.data[key].profile.organization)
          self.label_options.push(a);
        });
      });
      // }
    },

    checkAll: function () {
      if (this.isCheckAll) {
        this.items.forEach((item) => {
          const index = this.selectedVulns.indexOf(item.id);
          if (index < 0) this.selectedVulns.push(item.id);
        });
      } else {
        this.items.forEach((item) => {
          const index = this.selectedVulns.indexOf(item.id);
          this.selectedVulns.splice(index, 1);
          // }
        });
      }
    },
    updateCheckAll: function () {
      var if_all = 0;
      this.items.forEach((item) => {
        console.log("item", item.id);
        const index = this.selectedVulns.indexOf(item.id);
        if (index >= 0) {
          console.log(index);
          if_all++;
        }
      });
      if (if_all == this.items.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    getfilterData: function () {
      // if (this.isCheckAllFilterResults) {
      //   this.items.forEach((item) => {
      //     const index = this.selectedVulns.indexOf(item.id);
      //     if (index < 0) this.selectedVulns.push(item.id);
      //   });
      // } else {
      //   this.items.forEach((item) => {
      //     const index = this.selectedVulns.indexOf(item.id);
      //     this.selectedVulns.splice(index, 1);
      //     // }
      //   });
      // }
    },
    changeStatus: function (status) {
      var self = this;
      this.loading = true;
      let data = {
        status: status,
        vulnerabilties: this.selectedVulns,
      };
      if (this.isCheckAllFilterResults && this.is_search_on) {
        data.filters = this.filter;
      }
      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/update-vulnerability-in-bulk/",
      }).then((res) => {
        if (status == 9) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Deleted Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.searchFn(false);
        } else if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.searchFn(false);
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
      this.closeModal();
    },
    secusyServiceBulkAction: function (status) {
      // secusy service bulk action for mark_as_verified ,mark_as_unverified, request for retest

      var self = this;
      this.loading = true;
      let data = {
        status: status,
        vulnerabilties: this.selectedVulns,
      };
      if (this.isCheckAllFilterResults && this.is_search_on) {
        data.filters = this.filter;
      }
      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/secusy-service-bulk-action/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.searchFn(false);
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
      this.closeModal();
    },
    csvDownload: function () {
      let data = {
        vulnerabilties: this.selectedVulns,
      };
      if (this.isCheckAllFilterResults && this.is_search_on) {
        data.filters = this.filter;
      }
      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/vulns/export-as-csv/",
        responseType: "blob",
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `vulnerabilties-${Math.random()}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        console.log(res, "csv file");
        if (res.status == 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "downloaded successfully",
              // title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isloadingCsv = false;
      });
    },
    // for csv download btn
    getCSV: function () {
      if (!this.selectedVulns.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        if (confirm("Do you really want to download?")) {
          this.isloadingCsv = true;
          this.csvDownload();
        }
        this.selected_action = null;
      }
    },

    doBulkAction: function () {
      if (!this.selectedVulns.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      }

      switch (this.selected_action) {
        // case "export_to_csv":
        //   if (confirm("Do you really want to download?")) {
        //     this.csvDownload();
        //   }
        //   this.selected_action = null;
        //   break;
        case "mitigate":
          // Action to mitigate
          // Pop up for the task assignee
          this.$root.$emit(
            "bv::toggle::collapse",
            "sidebar-task-handler-taskform"
          );
          break;
        case "mark_as_closed":
          if (confirm("Do you really want to close the selected issues?")) {
            this.changeStatus(1);
          }
          this.selected_action = null;
          break;
        case "mark_as_false_positive":
          if (
            confirm(
              "Do you really want to mark as false positive the selected issues?"
            )
          ) {
            this.changeStatus(2);
          }
          this.selected_action = null;
          break;
        case "mark_as_open":
          if (confirm("Do you really want to (re)open the selected issues?")) {
            this.changeStatus(0);
          }
          this.selected_action = null;
          break;
        case "accept":
          if (
            confirm("Do you really want to accept the risk of selected issues?")
          ) {
            this.changeStatus(3);
          }
          this.selected_action = null;
          break;
        case "delete":
          //   if (confirm("Do you really want to delete the selected issues?")) {
          //     this.changeStatus(9);
          //   }
          this.loadBulkDelete();
          this.selected_action = null;
          break;
        case "mark_as_verified":
          if (confirm("Do you really want to mark all as verified?")) {
            this.secusyServiceBulkAction(10);
          }
          this.selected_action = null;
          break;
        case "mark_as_unverified":
          if (confirm("Do you really want to mark all as un-verified?")) {
            this.secusyServiceBulkAction(11);
          }
          this.selected_action = null;
          break;
        // new actions
        case "mark_as_exploited":
          if (confirm("Do you really want to mark all as exploited?")) {
            this.changeStatus(12);
          }
          this.selected_action = null;
          break;
        case "mark_as_unexploited":
          if (confirm("Do you really want to mark all as un-exploited?")) {
            this.changeStatus(13);
          }
          this.selected_action = null;
          break;
        case "assign_vuln":
          this.loadAssignVuln();
          this.selected_action = null;
          break;
        default:
          break;
      }
    },
    openMitigateModal: function () {
      if (!this.selectedVulns.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        this.$root.$emit(
          "bv::toggle::collapse",
          "sidebar-task-handler-taskform"
        );
      }
    },
    loadBulkDelete: function () {
      this.$refs["modal-bulk-delete"].show();
    },
    requestForRetest() {
      if (!this.selectedVulns.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        this.$refs["modal-retest-vuln"].show();
        this.selected_action = null;
      }
    },
    closeModal() {
      this.$refs["modal-bulk-delete"].hide();
      this.$refs["modal-retest-vuln"].hide();
      this.searchFn();
    },
    loadAssignVuln() {
      if (!this.selectedVulns.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        this.$refs["modal-assign-vuln"].show();
      }
    },
    closeAssignModal() {
      this.$refs["modal-assign-vuln"].hide();
      this.searchFn();
    },
    redirectToAssetScan(asset_id) {
      const assetId = asset_id;
      this.$router.push(`/assets/scan/${assetId}`);
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
::v-deep .v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  max-height: 300px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bg-background {
  background: #fff !important;
}
[dir] .dark-layout .bg-background {
  // background: #283046 !important;
  background: #253e42 !important;
  border-color: #404656;
}
</style>